import { FC } from 'react'
import { formatDate } from 'utils'
import RefundPayoutDateEdit from './RefundPayoutDateEdit'

interface RefundPayoutDateProps {
  orderLineId: string
  currentPaymentDate: Date
  isPayout: boolean
  onRefetch: () => void
}
const RefundPayoutDate: FC<RefundPayoutDateProps> = ({ orderLineId, currentPaymentDate, onRefetch }) => {
  return (
    <div className="flex items-center space-x-2">
      <div className="text-gray-500 text-sm">Payout date: {formatDate(currentPaymentDate)}</div>
      <RefundPayoutDateEdit orderLineId={orderLineId} currentPaymentDate={currentPaymentDate} onRefetch={onRefetch} />
    </div>
  )
}

export default RefundPayoutDate
