import { Modal } from '@plusplusminus/plusplusdash'
import Button from 'components/Button'

type RemoveItemProps = {
  isLoading: boolean
  name?: string
  isOpen: boolean
  onClose: () => void
  removeItem: () => void
}
export default function RemoveItemModal({
  isLoading,
  isOpen,
  name,
  onClose,
  removeItem
}: RemoveItemProps): JSX.Element {
  return (
    <Modal isOpen={isOpen} size="sm" onClose={onClose}>
      <Modal.Title>Are you sure you want to remove the "{name}"?</Modal.Title>
      <Modal.Description>
        <div className="flex justify-end gap-2 mt-8">
          <Button variant="plain" colorScheme="red" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="primary" colorScheme="red" onClick={removeItem} isDisabled={isLoading} isLoading={isLoading}>
            Yes, Remove
          </Button>
        </div>
      </Modal.Description>
    </Modal>
  )
}
