import { Box, Button, Checkbox, FormLabel, Radio } from '@plusplusminus/plusplusdash'
import { RouteComponentProps, WindowLocation } from '@reach/router'
import { GraphqlOperations } from 'common/types'
import {
  Configure,
  InstantSearch,
  RefinementList,
  SearchBox,
  SortBy,
  useInfiniteHits,
  useInstantSearch
} from 'react-instantsearch-hooks-web'
import { ALGOLIA_FILTERS, ALGOLIA_PRODUCT_INDEX_NAME, searchClient } from './algolia-client'
// import { history } from 'instantsearch.js/cjs/lib/routers/index.js'
import cn from 'classnames'
import { formatPrice } from 'common/utils'
import { createInfiniteHitsSessionStorageCache } from 'instantsearch.js/es/lib/infiniteHitsCache'
import { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { BulkTagModal } from './BulkTagModal'
import BulkTagProductSelection from './BulkTagProductSelection'

export type SelectedProduct = {
  id: string
  image: string
  title: string
  price: number
  brand: string
}

const sessionStorageCache = createInfiniteHitsSessionStorageCache()

export const BulkTag: React.FC<RouteComponentProps<{ location: WindowLocation<any> }>> & GraphqlOperations = () => {
  useEffect(() => localStorage.setItem('products', JSON.stringify([])), [])
  return (
    <Box className="flex flex-col min-h-screen bg-white">
      <Box className="flex p-5 justify-between border-b border-gray-200 bg-white sticky top-0 z-10">
        <h1 className="text-lg">Bulk Tag - Product Selection</h1>
      </Box>

      <Box className="flex-grow flex flex-col">
        <InstantSearch searchClient={searchClient} indexName={ALGOLIA_PRODUCT_INDEX_NAME} routing={true}>
          <Configure hitsPerPage={20} filters={ALGOLIA_FILTERS} distinct={true} />
          <div className="flex flex-col justify-between md:flex-row">
            <Filters />
            <div className="w-full md:w-9/12">
              <InfiniteHitsScroll />
            </div>
          </div>
        </InstantSearch>
      </Box>
    </Box>
  )
}

const onViewProduct = (objectID: string) => {
  window.open(`${window.origin}/products/${objectID}`, '_blank', 'noreferrer')
}

const Filters = () => {
  const [tagOperator, setTagOperator] = useState<'or' | 'and'>('or')
  return (
    <aside className="sticky z-10 block w-full flex-shrink-0 self-start overflow-y-auto bg-white product-search-aside md:w-3/12 p-5">
      <SearchBox
        placeholder="Search all products"
        classNames={{
          input:
            'z-0 appearance-none min-w-0 w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-4 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:border-gray-900 focus:ring-1 focus:ring-gray-900',
          form: 'relative mb-5',
          submitIcon: 'absolute top-4 right-3',
          resetIcon: 'absolute top-4 right-3 bg-white'
        }}
      />
      <div className="mb-5 space-y-2">
        <span className="text-bold relative">Sort By</span>
        <SortBy
          items={[
            { value: 'product_variants', label: 'All Products' },
            { value: 'product_variants_price_asc', label: 'Price (low to high)' },
            { value: 'product_variants_price_desc', label: 'Price (high to low)' }
          ]}
        />
      </div>

      <div className="mb-5 space-y-2">
        <span className="text-bold relative uppercase">Brands</span>
        <RefinementList
          className="space-y-4"
          attribute="brand"
          operator="or"
          sortBy={['isRefined', 'name:asc']}
          searchable={true}
          searchablePlaceholder={'Search for a brand'}
          showMore
          limit={6}
          showMoreLimit={1000}
          classNames={{
            list: 'space-y-2',
            item: '',
            label: 'text-sm block sm:dark:hover:bg-gray transition ease-in-out duration-150 font-normal',
            selectedItem: 'selected',
            count: 'hidden',
            checkbox: '!focus:outline-0',
            labelText: 'pl-3',
            searchBox: 'relative',
            showMore: 'text-sm text-center bg-gray-900 rounded text-white px-4 py-1 w-full'
          }}
        />
      </div>
      <div className="mb-5 space-y-2">
        <span className="text-bold relative uppercase">Tags</span>
        <div className="">
          <div className="flex space-x-2 items-center mb-4">
            <FormLabel htmlFor="or" className="flex items-center">
              <Radio
                id="or"
                name="or"
                checked={tagOperator === 'or'}
                onClick={() => {
                  setTagOperator('or')
                }}
              />
              Or
            </FormLabel>
            <FormLabel htmlFor="and" className="flex items-center">
              <Radio
                id="and"
                name="and"
                checked={tagOperator === 'and'}
                onClick={() => {
                  setTagOperator('and')
                }}
              />
              And
            </FormLabel>
          </div>
        </div>
        <RefinementList
          className="space-y-4"
          attribute="tags"
          operator={tagOperator}
          sortBy={['isRefined', 'name:asc']}
          limit={6}
          searchable={true}
          searchablePlaceholder={'Search for a tag'}
          showMore
          showMoreLimit={1000}
          classNames={{
            list: 'space-y-2',
            item: '',
            label: 'text-sm block sm:dark:hover:bg-gray transition ease-in-out duration-150 font-normal',
            selectedItem: 'selected',
            count: 'hidden',
            checkbox: '',
            labelText: 'pl-3',
            searchBox: 'relative',
            showMore: 'text-sm text-center bg-gray-900 rounded text-white px-4 py-1 w-full'
          }}
        />
      </div>
    </aside>
  )
}

function InfiniteHitsScroll() {
  const { refresh } = useInstantSearch()
  const { hits, showMore, isLastPage, results } = useInfiniteHits({
    cache: sessionStorageCache
  })

  const { ref: sentinelRef, inView } = useInView()
  const [isOpen, setIsOpen] = useState(false)
  const [selectedProducts, setSelectedProducts] = useState<SelectedProduct[]>([])
  const [selectAll, setSelectAll] = useState(false)

  const onClose = () => setIsOpen(false)
  const onOpen = () => setIsOpen(true)
  const onRefresh = () => {
    sessionStorage.removeItem('ais.infiniteHits')
    refresh()
    clearSelection()
  }
  const onAdd = (selectedProduct: SelectedProduct) => {
    const stringifyProducts = localStorage.getItem('products')
    if (!stringifyProducts) {
      localStorage.setItem('products', JSON.stringify([selectedProduct]))
    } else {
      const parsedProducts = JSON.parse(stringifyProducts) as SelectedProduct[]
      const foundIndex = parsedProducts.findIndex((product) => product.id === selectedProduct.id)
      let products
      if (foundIndex > -1) {
        products = [...parsedProducts.slice(0, foundIndex), ...parsedProducts.slice(foundIndex + 1)]
      } else {
        products = [...parsedProducts, selectedProduct]
      }
      localStorage.setItem('products', JSON.stringify(products))
      setSelectedProducts(products)
    }
    // localStorage.setItem('')}
  }
  const clearSelection = () => {
    localStorage.setItem('products', JSON.stringify([]))
    setSelectedProducts([])
    setSelectAll(false)
  }
  useEffect(() => {
    if (inView && !isLastPage) {
      showMore()
    }
  }, [inView, showMore, isLastPage])

  useEffect(() => {
    if (selectAll) {
      const stringifyProducts = localStorage.getItem('products')
      const productsListed: SelectedProduct[] =
        hits?.map(
          ({ product_id: id, title, product_image: image, price, brand }) =>
            ({ id, title, image, price, brand } as SelectedProduct)
        ) ?? []
      if (!stringifyProducts || stringifyProducts === '[]') {
        setSelectedProducts(productsListed)
        localStorage.setItem('products', JSON.stringify(productsListed))
      } else {
        const parsedProducts = JSON.parse(stringifyProducts) as SelectedProduct[]
        const products = [
          ...parsedProducts,
          ...productsListed.filter(
            (productListed) => !parsedProducts.filter((parseProduct) => parseProduct.id !== productListed.id)
          )
        ]
        setSelectedProducts(products)
        localStorage.setItem('products', JSON.stringify(products))
      }
    }
  }, [selectAll, hits])

  useEffect(() => {
    sessionStorage.removeItem('ais.infiniteHits')
    const stringifyProducts = localStorage.getItem('products')
    if (stringifyProducts) {
      setSelectedProducts(JSON.parse(stringifyProducts))
    }
  }, [])

  return hits?.length > 0 ? (
    <>
      <div className="sticky flex-wrap flex items-center space-x-5 p-5 bg-white product-search-list-toolbar z-10 border-b border-gray-200">
        <div>
          <strong>Products listed:</strong> {hits.length} / {results?.nbHits}
        </div>
        <div>
          <strong>Products selected:</strong> {selectedProducts.length}
        </div>
        <Button variant="plain" onClick={clearSelection}>
          Clear Selection
        </Button>
        <div className="flex spacex-2 items-center">
          <Checkbox id="selectAll" aria-label="enabled" checked={selectAll} onChange={() => setSelectAll(!selectAll)} />
          Select All
        </div>
        <Button variant="primary" colorScheme="green" isDisabled={selectedProducts.length === 0} onClick={onOpen}>
          Bulk Tag
        </Button>
        <BulkTagProductSelection selectedProducts={selectedProducts} onAdd={onAdd} />
      </div>
      <div className="relative grid grid-cols-1 gap-5 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 py-5 pr-5">
        {hits.map((hit) => {
          const { objectID, product_id, title, product_image, price, brand, tags } = hit
          const selected = !!selectedProducts.find((selectedProduct) => selectedProduct.id === product_id)
          return (
            <div
              key={objectID}
              className="group-item relative cursor-pointer"
              onClick={() => onViewProduct(product_id as string)}
            >
              <div
                className={cn('relative p-5 border border-gray-200 rounded-md overflow-hidden h-full', {
                  'shadow-xl': selected
                })}
              >
                <div className="product-image-wrapper">
                  <img
                    src={`${product_image}&width=400`}
                    alt=""
                    className="absolute top-0 left-0 h-full w-full object-cover"
                  />
                </div>
                <p>{`By ${brand}`}</p>
                <p className="font-semibold">{(title as string).substring(0, 30)}</p>
                <p className="font-semibold">{price ? formatPrice(price as number) : ''}</p>
                {tags && (tags as string[]).length > 0 && (
                  <div className="mt-2 mb-5">
                    {(tags as string[]).slice(0, 8).map((tag, index) => (
                      <span key={index} className="inline-flex mr-1 bg-gray-200 text-xs px-1 py-1 mb-1 rounded-sm">
                        {tag}
                      </span>
                    ))}
                    {(tags as string[]).length > 8 && (
                      <span className="inline-flex mr-1 bg-gray-200 text-xs px-1 py-1 mb-1 rounded-sm">...</span>
                    )}
                  </div>
                )}
                <div className="absolute bottom-2 w-full flex items-center justify-between pr-7">
                  <span className="font-semibold">View product</span>
                  <Checkbox
                    id="productSelection"
                    aria-label="enabled"
                    disabled={selectAll}
                    checked={selected}
                    onClick={(e) => {
                      e.stopPropagation()
                      !selectAll &&
                        onAdd({ id: product_id, title, image: product_image, price, brand } as SelectedProduct)
                    }}
                  />
                </div>
              </div>
            </div>
          )
        })}
      </div>
      {!isLastPage && (
        <div ref={sentinelRef} className="p-4 text-center text-gray-600">
          Loading more items...
        </div>
      )}
      <BulkTagModal isOpen={isOpen} selectedProducts={selectedProducts} onClose={onClose} onRefresh={onRefresh} />
    </>
  ) : (
    <h2 className="text-2xl font-semibold text-red-500">No results</h2>
  )
}
