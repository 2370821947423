import { Disclosure } from '@headlessui/react'
import { CheckIcon, ChevronUpIcon } from '@heroicons/react/solid'
import { Table } from '@plusplusminus/plusplusdash'
import classNames from 'classnames'
import dayjs from 'dayjs'
import { AccountCode, OrderLedger } from 'generated'
import { useMemo } from 'react'
import AddAccountingLineItem from './AddAccountingLine'
import DeleteAccountingLine from './DeleteAccountingLine'
import EditAccountingLine from './EditAccountingLine'
import ReprocessInvoiceButton from 'components/ReprocessInvoiceButton/ReprocessInvoiceButton'
import SendStatement from './SendStatement'

const TABLE_SHAPE = [
  { label: 'Code', key: 'code', isSortable: false },
  { label: 'Description', key: 'description', isSortable: false },
  { label: 'Amount (R)', key: 'amount', isSortable: false },
  { label: 'Is Credit?', key: 'isCredit', isSortable: false },
  { label: 'Created At', key: 'createdAt', isSortable: false },
  { label: '', key: '', isSortable: false }
]

const styles = {
  icon: 'w-4 h-4 text-gray-700 mr-3'
}

interface AccountingLinesProps {
  onRefetch: () => void
  orderLineId: string
  brandId: string
  accountingCodes: AccountCode[]
  currentAccountingLines: OrderLedger[]
  invoiceUrl?: string
  paymentAdviceUrl?: string
}
const AccountingLines: React.FC<AccountingLinesProps> = ({
  orderLineId,
  brandId,
  accountingCodes,
  onRefetch,
  currentAccountingLines,
  invoiceUrl,
  paymentAdviceUrl
}) => {
  const accountingCodesMap = useMemo(
    () =>
      accountingCodes.reduce((acc, cur) => {
        acc[cur.code] = cur.description
        return acc
      }, {} as Record<string, string>),
    []
  )
  const total = currentAccountingLines.reduce((acc, cur) => acc + cur.amount, 0)
  return (
    <div className="border-t border-gray-200 xl:border-none pt-3 xl:pt-0">
      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button className="w-full">
              <div className="flex items-center space-x-2">
                <span>Accounting Options</span>
                <ChevronUpIcon className={classNames(styles.icon, 'ml-8', { 'transform rotate-180': !open })} />
              </div>
            </Disclosure.Button>
            <Disclosure.Panel>
              <div className="pt-5 max-w-5xl">
                <Table shape={TABLE_SHAPE}>
                  {currentAccountingLines.map((accountingLine) => {
                    const { id, isCredit, amount, code, description, createdAt } = accountingLine
                    return (
                      <Table.Row className="border-b">
                        <Table.Cell className="w-32">{`${code} ${accountingCodesMap[code] ?? ''}`}</Table.Cell>
                        <Table.Cell>{description}</Table.Cell>
                        <Table.Cell className="w-40 text-right">R {amount.toFixed(2)}</Table.Cell>

                        <Table.Cell className="w-32">
                          {isCredit ? <CheckIcon className="w-5 h-5 text-green-500" /> : ''}
                        </Table.Cell>
                        <Table.Cell>{dayjs(createdAt).format('DD-MM-YYYY')}</Table.Cell>
                        <Table.Cell className="w-32">
                          <div className="flex items-center space-x-2">
                            <EditAccountingLine
                              accountingLine={accountingLine}
                              accountingCodes={accountingCodes}
                              onRefetch={onRefetch}
                            />
                            <DeleteAccountingLine accountingLineId={id ?? ''} onRefetch={onRefetch} />
                          </div>
                        </Table.Cell>
                      </Table.Row>
                    )
                  })}
                  {currentAccountingLines.length > 0 && (
                    <Table.Row className="border-b">
                      <Table.Cell className="w-32">{''}</Table.Cell>
                      <Table.Cell className="font-bold">Balance</Table.Cell>
                      <Table.Cell className="w-40 text-right font-bold">R {total.toFixed(2)}</Table.Cell>

                      <Table.Cell className="w-32">{''}</Table.Cell>
                      <Table.Cell>{''}</Table.Cell>
                      <Table.Cell className="w-32">{''}</Table.Cell>
                    </Table.Row>
                  )}
                </Table>

                <div className="flex justify-between items-center">
                  <AddAccountingLineItem
                    orderLineId={orderLineId}
                    accountingCodes={accountingCodes}
                    onRefetch={onRefetch}
                  />
                  <div className="mx-2">
                    {invoiceUrl ? <ReprocessInvoiceButton orderLineId={orderLineId} onRefetch={onRefetch} /> : null}
                  </div>

                  {invoiceUrl ? (
                    <a
                      className="block underline cursor-pointer text-right hover:underline mr-2"
                      href={invoiceUrl}
                      target="__blank"
                    >
                      Download Invoice
                    </a>
                  ) : null}
                  {paymentAdviceUrl ? (
                    <a
                      className="block underline cursor-pointer text-right hover:underline mr-2"
                      href={paymentAdviceUrl}
                      target="__blank"
                    >
                      Download Payment Advice
                    </a>
                  ) : null}
                  <SendStatement brandId={brandId} />
                </div>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  )
}

export default AccountingLines
