import { CheckIcon } from '@heroicons/react/solid'
import { Checkbox, Dropdown, FormField, FormLabel, Radio } from '@plusplusminus/plusplusdash'
import cn from 'classnames'
import { Direction } from 'common/enums'
import FilterWrapper from 'components/FilterWrapper'
import { useAllBrandsQuery } from 'hooks/brands'
import { TUseFilter } from 'hooks/useFilter'
import { SortTuple } from 'hooks/useSort'
import React from 'react'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'

const stateOptions = [
  { key: 'pending', label: 'Pending', value: 'Pending' },
  { key: 'settled', label: 'Settled', value: 'Settled' },
  { key: 'failed', label: 'Failed', value: 'Failed' }
]

const dropdownItemStyle = 'block w-full text-left px-5 py-2 hover:bg-gray-100 text-sm rounded-sm cursor-pointer'
const sortItems = [
  { key: 'created_asc', label: 'Created Date: Ascending', value: 'createdAt', direction: Direction.ASC },
  { key: 'created_desc', label: 'Created Date: Descending', value: 'createdAt', direction: Direction.DESC },
  { key: 'updated_asc', label: 'Updated Date: Ascending', value: 'updatedAt', direction: Direction.ASC },
  { key: 'updated_desc', label: 'Updated Date: Descending', value: 'updatedAt', direction: Direction.DESC }
]

type RefundsFilterProps = {
  filtering: TUseFilter
  sort: SortTuple
  isBrand?: boolean
}

const RefundsFilter: React.FC<RefundsFilterProps> = (props) => {
  const {
    filtering: { filter, setFilter, pushFilter, removeFilter },
    sort,
    isBrand = false
  } = props

  const [sortField, direction, , setSort, removeSort] = sort

  const { data, loading: loadingBrands } = useAllBrandsQuery({ skip: isBrand })

  const customSort = (name: string, direction: Direction) => () => {
    setSort(name, direction)
  }
  console.log({ filter })
  return (
    <>
      <div className="flex gap-2"></div>
      <div className="flex gap-2">
        {!loadingBrands && !isBrand ? (
          <FilterWrapper
            canRemoveFilter={!!filter.brands}
            removeFilterAction={() => removeFilter('brands', undefined, { isArray: true })}
          >
            <Dropdown>
              <Dropdown.Button>Brands</Dropdown.Button>
              <Dropdown.Items>
                <div className="py-2 px-4">
                  <FormLabel className="font-medium mb-2">Select Brands to filter by</FormLabel>
                  <div className="max-h-64 overflow-y-auto px-2">
                    {data?.allBrands.map((brand) => (
                      <Dropdown.Item>
                        <FormLabel
                          key={brand.id}
                          htmlFor={brand.id}
                          className="flex gap-2 items-center my-1 cursor-pointer"
                        >
                          <Checkbox
                            id={brand.id}
                            name="brand"
                            checked={filter.brands?.includes(brand.id)}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              console.log({ e })
                              console.log("I'm changed")
                              if (e.target.checked) {
                                pushFilter('brands', brand.id)
                              } else {
                                removeFilter('brands', brand.id)
                              }
                            }}
                          />
                          <span>{brand.brandName}</span>
                        </FormLabel>
                      </Dropdown.Item>
                    ))}
                  </div>
                </div>
              </Dropdown.Items>
            </Dropdown>
          </FilterWrapper>
        ) : null}
        <FilterWrapper canRemoveFilter={filter.state} removeFilterAction={() => removeFilter('state')}>
          <Dropdown>
            <Dropdown.Button>Refund State</Dropdown.Button>
            <Dropdown.Items className="w-60">
              <div className="py-2 px-4">
                {stateOptions.map((option) => (
                  <Dropdown.Item>
                    <FormField key={option.key} direction="row" className="mr-2">
                      <FormLabel htmlFor={`refundState-${option.value}`} className="cursor-pointer">
                        <Radio
                          id={`refundState-${option.value}`}
                          name="state"
                          aria-label={option.label}
                          onClick={(e) => {
                            e.stopPropagation()
                            setFilter('state', option.value)
                          }}
                          checked={filter?.state === option.value}
                        />
                        {option.label}
                      </FormLabel>
                    </FormField>
                  </Dropdown.Item>
                ))}
              </div>
            </Dropdown.Items>
          </Dropdown>
        </FilterWrapper>
        <FilterWrapper canRemoveFilter={!!sortField} removeFilterAction={removeSort}>
          <Dropdown>
            <Dropdown.Button>Sort By</Dropdown.Button>
            <Dropdown.Items className="z-10">
              {sortItems.map((item) => (
                <Dropdown.Item>
                  <button
                    key={item.key}
                    className={cn(dropdownItemStyle, 'flex justify-between items-center')}
                    onClick={customSort(item.value, item.direction)}
                  >
                    {item.label}
                    {sortField === item.value && direction?.value === item.direction ? (
                      <CheckIcon className="h-4 w-4 text-green-600" />
                    ) : null}
                  </button>
                </Dropdown.Item>
              ))}
            </Dropdown.Items>
          </Dropdown>
        </FilterWrapper>
      </div>
    </>
  )
}

export { RefundsFilter }
