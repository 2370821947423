import { gql } from '@apollo/client'

const ACCOUNT_FIELDS = gql`
  fragment AccountFields on Account {
    id
    email
    firstName
    lastName
    status
    enabled
    roles
    createdAt
    permissions
    # lastSeenAt
    # updatedAt
  }
`

const IMAGE_FIELDS = gql`
  fragment ImageFields on Image {
    id
    key
    url
    sourceType
  }
`

const TAG_FIELDS = gql`
  ${IMAGE_FIELDS}
  fragment TagFields on Tag {
    id
    name
    description
    types {
      name
    }
    createdAt
    updatedAt
    category
    categoryLabel
    image {
      ...ImageFields
    }
    meta
  }
`

const META_FIELDS = gql`
  fragment MetaFields on Meta {
    fields {
      title
      link
      isExternal
    }
  }
`

const BRAND_META_FIELDS = gql`
  fragment BrandMetaFields on BrandMeta {
    fields {
      title
      link
      isExternal
    }
    includeRecentlySold
    location
  }
`

const NOTES_FIELDS = gql`
  fragment NotesFields on Notes {
    product
    cart
    alert
    checkout
    shipping
    freeShippingThreshold
  }
`

const BRAND_FIELDS = gql`
  fragment BrandFields on Brand {
    id
    name
    providerName
    brandName
    enabled
    verified
    marketingName
    marketingDescription
    marketingColor
    tags {
      ...TagFields
    }
    image {
      ...ImageFields
    }
    images {
      ...ImageFields
    }
    featuredPortrait {
      ...ImageFields
    }
    featuredLandscape {
      ...ImageFields
    }
  }
  ${TAG_FIELDS}
`

const BRAND_DETAIL_FIELDS = gql`
  fragment BrandDetailFields on Brand {
    description
    providerDescription
    brandDescription

    address

    currency
    locale
    ownerName
    terms
    privacyPolicy
    returnPolicy
    shippingPolicy

    lastSync
    installUrl

    phone
    providerPhone
    brandCellphone

    website
    websiteOverride
    brandWebsite

    email
    providerEmail
    brandEmail

    facebook
    instagram

    meta {
      ...BrandMetaFields
    }
    notes {
      ...NotesFields
    }

    commission
    paymentTerms {
      unit
      amount
    }

    bankDetails {
      accountNumber
      branchCode
      bankName
    }

    shopify {
      id
      appId
      domain
    }

    adminEmails

    hiddenFields
    vatNumber
    invoiceAddress {
      address1
      address2
      city
      province
      zip
    }
    productSort
    productSortFriendly

    recommendedProductTags
    hiddenFields

    locations {
      id
      name
      address1
    }
  }
  ${TAG_FIELDS}
  ${BRAND_META_FIELDS}
  ${NOTES_FIELDS}
`

const OPTION_NAME_FIELDS = gql`
  fragment OptionNameFields on OptionName {
    id
    name
  }
`

const OPTION_FIELDS = gql`
  ${OPTION_NAME_FIELDS}
  fragment OptionFields on ProductOption {
    id
    value
    name {
      ...OptionNameFields
    }
  }
`

const VARIANT_FIELDS = gql`
  fragment VariantFields on ProductVariant {
    id
    title
    price
    inventory
    sku
    inventoryPolicy
  }
`

const PRODUCT_FIELDS = gql`
  fragment ProductFields on Product {
    id
    title
    providerTitle
    productTitle

    description
    providerDescription
    productDescription
    description
    tracksInventory
    image {
      ...ImageFields
    }
    images {
      ...ImageFields
    }
    providerImages {
      ...ImageFields
    }
    enabled
    createdAt
    updatedAt
    brand {
      ...BrandFields
    }
    options {
      ...OptionFields
    }
    variants {
      ...VariantFields
      selectedOptions {
        name
        value
      }
      images {
        ...ImageFields
      }
    }

    tags {
      ...TagFields
    }
    providerTags

    # provider fields
    providerImage
    providerImages {
      url
    }
    isAutoPublish
    isImageContained

    meta {
      ...MetaFields
    }
    notes {
      ...NotesFields
    }
  }
  ${TAG_FIELDS}
  ${BRAND_FIELDS}
  ${IMAGE_FIELDS}
  ${VARIANT_FIELDS}
  ${OPTION_FIELDS}
  ${META_FIELDS}
  ${NOTES_FIELDS}
`

const PAGING_FIELDS = gql`
  fragment PagingFields on PageInfo {
    hasNextPage
    hasPreviousPage
    startCursor
    endCursor
  }
`

export {
  ACCOUNT_FIELDS,
  TAG_FIELDS,
  BRAND_FIELDS,
  PRODUCT_FIELDS,
  PAGING_FIELDS,
  IMAGE_FIELDS,
  VARIANT_FIELDS,
  OPTION_NAME_FIELDS,
  META_FIELDS,
  NOTES_FIELDS,
  BRAND_DETAIL_FIELDS
}
