import { gql, useMutation } from '@apollo/client'
import { IMAGE_FIELDS } from 'graphql/fragments'

const UPLOAD_IMAGE = gql`
  ${IMAGE_FIELDS}
  mutation UploadOneImage($file: Upload!) {
    uploadOneImage(file: $file) {
      ...ImageFields
    }
  }
`
interface UseUploadArgs {
  onSuccess?: (image: any) => void
  onError?: (e: Error) => void
}

function useUploadImage({ onSuccess, onError }: UseUploadArgs): any {
  const [upload, { loading, data }] = useMutation(UPLOAD_IMAGE, {
    context: {
      headers: {
        'apollo-require-preflight': true
      }
    }
  })

  const uploadOneImage = async (file: any) => {
    try {
      const uploadFile = await upload({
        variables: {
          file
        }
      })

      onSuccess && onSuccess(uploadFile.data?.uploadOneImage)
    } catch (err: any) {
      onError && onError(err)
    }
  }

  return {
    uploadOneImage,
    uploading: loading,
    uploadedImage: data?.uploadOneImage
  }
}

export { useUploadImage }
