import { FC, useState } from 'react'
import { Button, Input, Modal } from '@plusplusminus/plusplusdash'
import { gql, useMutation } from '@apollo/client'
import { SubmitHandler, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'

interface SendStatementProps {
  brandId: string
}

interface DateRangeInput {
  startDate: string
  endDate: string
  sendToBrand?: boolean
}

const SendStatement: FC<SendStatementProps> = ({ brandId }) => {
  const [isOpen, setIsOpen] = useState(false)
  const { register, handleSubmit } = useForm<DateRangeInput>()

  const [processBrandStatement, { loading, error }] = useMutation(gql`
    mutation ProcessBrandStatement($startDate: String!, $endDate: String!, $brandId: String!, $sendToBrand: Boolean!) {
      processBrandStatement(startDate: $startDate, endDate: $endDate, brandId: $brandId, sendToBrand: $sendToBrand)
    }
  `)

  const onOpen = () => setIsOpen(true)
  const onClose = () => setIsOpen(false)

  const onSubmit: SubmitHandler<DateRangeInput> = async ({ startDate, endDate, sendToBrand }) => {
    try {
      await processBrandStatement({
        variables: {
          startDate,
          endDate,
          brandId,
          sendToBrand
        }
      })
      toast.success('Brand statement processed successfully!')

      onClose() // Close modal
    } catch (err) {
      toast.error('Failed to process brand statement')
    }
  }

  return (
    <>
      <Button isDisabled={loading} key="edit-brand-button" variant="plain" colorScheme="blue" onClick={onOpen}>
        Send Statements
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size="sm">
        <Modal.Title>Select Period</Modal.Title>
        <Modal.Description className="my-4">
          <form onSubmit={handleSubmit(onSubmit)}>
            <label className="block mb-2">
              Start Date:
              <Input
                as="input"
                variant="outline"
                width="full"
                id="startDate"
                name="startDate"
                type="date"
                ref={register({ required: true })}
              />
            </label>
            <label className="block mb-4">
              End Date:
              <Input
                as="input"
                variant="outline"
                width="full"
                id="endDate"
                name="endDate"
                type="date"
                ref={register({ required: true })}
              />
            </label>

            <label className="block mb-4">
              Send to Brand:
              <input type="checkbox" className="ml-2" name="sendToBrand" ref={register({ required: false })} />
            </label>
            <Button variant="primary" type="submit" disabled={loading}>
              {loading ? 'Processing...' : 'Submit'}
            </Button>
            {error && <p className="text-red-500 mt-2">Error: {error.message}</p>}
          </form>
        </Modal.Description>
      </Modal>
    </>
  )
}

export default SendStatement
