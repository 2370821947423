import { DocumentNode, gql } from '@apollo/client'
import { BookmarkIcon, CheckIcon, ClipboardCopyIcon, PencilIcon, SwitchVerticalIcon } from '@heroicons/react/solid'
import { Alert, Box, Button, Dropdown, Loader } from '@plusplusminus/plusplusdash'
import { navigate } from '@reach/router'
import { useBrandQuery, useIndexBrandMutation, useSetupWebhookMutation } from 'generated'
import { BRAND_DETAIL_FIELDS, BRAND_FIELDS } from 'graphql/fragments'
import { useDeleteBrandMutation } from 'hooks/brands'
import { useState } from 'react'
import { toast } from 'react-hot-toast'
import { Page } from '../../common/types'
import { BrandEdit } from '../../components/Brand/BrandEdit'
import { BrandView } from '../../components/Brand/BrandView'
import { DeleteModal } from '../../components/Modals/Delete'
import SendStatement from 'components/AccountingLines/SendStatement'

interface BrandRouteProps {
  brandId: string
}

const dropdownItemStyle = 'px-4 py-2 w-full text-left text-sm flex items-center justify-between hover:bg-gray-50'

export const Brand: Page<BrandRouteProps> & { query: { brand: DocumentNode; productSort: DocumentNode } } = (props) => {
  const { brandId = '' } = props

  const { data, loading: loadingBrand, refetch, error } = useBrandQuery({ variables: { id: brandId } })
  const [indexBrandMutation] = useIndexBrandMutation({
    onCompleted: () => {
      toast.success('Successfully indexed brand.')
    },
    onError: (error) => {
      console.log({ error })
      toast.error('Error. could not index brand')
    }
  })
  const [setupWebhookMutation] = useSetupWebhookMutation({
    onCompleted: () => toast.success('Successfully setup webhook.'),
    onError: (error) => {
      console.log({ error })
      toast.error('Error. could not setup webhook')
    }
  })

  const indexBrand = () => indexBrandMutation({ variables: { id: brandId } })
  const setupWebhook = () => setupWebhookMutation({ variables: { id: brandId } })

  const [isEditing, setIsEditing] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [copied, setCopied] = useState(false)

  const { deleteBrand, loading } = useDeleteBrandMutation({
    onCompleted: () => {
      toast.success('Successfully deleted brand.')
    },
    onError: (error) => {
      console.log({ error })
      toast.error('Server error. could not delete brand.')
    }
  })

  const copyInstallLinkToClipboard = () => {
    if (brand?.installUrl) {
      navigator.clipboard.writeText(brand.installUrl).then(() => {
        setCopied(true)
      })
    }
  }

  if (error?.graphQLErrors) {
    return (
      <Alert type="error">
        <Alert.Heading>Error fetching brand</Alert.Heading>
        <Alert.Description>An unexpected error occured when fetching the specified brand</Alert.Description>
      </Alert>
    )
  }

  if (loadingBrand) {
    return <Loader isActive />
  }

  if (!data || !data.brand) {
    return null
  }

  const { brand } = data

  return (
    <Box>
      <DeleteModal
        isLoading={loading}
        headline="Delete Brand"
        description="Are you sure you want to delete this brand?"
        isModalOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onDelete={() => {
          deleteBrand(brand.id)
          navigate('/brands', { state: { refetch: true } })
        }}
      />
      <Box className="flex justify-between p-5 border-b border-gray-300 items-center bg-white">
        <Box>
          <h3 className="text-lg leading-6 font-medium text-gray-900">Brand Information</h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">Brand details for viewing and editing</p>
        </Box>
        <div className="flex gap-2">
          <SendStatement brandId={brand.id} />
          <Dropdown>
            <Dropdown.Button>Actions</Dropdown.Button>
            <Dropdown.Items>
              <Dropdown.Item>
                <button className={dropdownItemStyle} onClick={indexBrand}>
                  <span>Index Brand</span>
                  <BookmarkIcon className="h-4 w-4" />
                </button>
              </Dropdown.Item>
              <Dropdown.Item>
                <button className={dropdownItemStyle} onClick={setupWebhook}>
                  <span>Setup Webhooks</span>
                  <SwitchVerticalIcon className="h-4 w-4" />
                </button>
              </Dropdown.Item>
              <Dropdown.Item>
                {brand.installUrl ? (
                  <button className={dropdownItemStyle} onClick={copyInstallLinkToClipboard}>
                    <span>Copy Install Link</span>
                    {copied ? (
                      <CheckIcon className="w-4 h-4 text-green-500" />
                    ) : (
                      <ClipboardCopyIcon className="w-4 h-4" />
                    )}
                  </button>
                ) : null}
              </Dropdown.Item>
            </Dropdown.Items>
          </Dropdown>
          {isEditing ? (
            <Button
              key="delete-brand-button"
              variant="primary"
              type="button"
              size="sm"
              colorScheme="red"
              onClick={() => {
                setIsModalOpen(true)
              }}
            >
              Delete brand
            </Button>
          ) : (
            <div className="flex gap-2">
              <Button key="edit-brand-button" variant="primary" colorScheme="blue" onClick={() => setIsEditing(true)}>
                <span className="flex justify-between">
                  <span className="mr-2">Edit</span>
                  <PencilIcon className="w-4 h-4" />
                </span>
              </Button>
            </div>
          )}
        </div>
      </Box>
      {isEditing ? (
        <BrandEdit
          brandId={brandId as string}
          brand={brand}
          cancel={() => setIsEditing(false)}
          updateCallback={() => {
            refetch()
            setIsEditing(false)
          }}
        />
      ) : (
        <BrandView brandId={brandId} brand={brand} refetch={refetch} />
      )}
    </Box>
  )
}

Brand.query = {
  brand: gql`
    query Brand($id: ID!) {
      brand(id: $id) {
        ...BrandFields
        ...BrandDetailFields
      }
    }
    ${BRAND_FIELDS}
    ${BRAND_DETAIL_FIELDS}
  `,
  productSort: gql`
    query ProductSort {
      brandProductSortOptions {
        label
        value
      }
    }
  `
}
