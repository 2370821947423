import { gql } from '@apollo/client'
import { Button, Checkbox, Form, FormError, FormLabel, Input, Loader } from '@plusplusminus/plusplusdash'
import { RouteComponentProps, useLocation, useNavigate } from '@reach/router'
import AccountGroupComboBox from 'components/AccountGroupComboBox'
import Actions from 'components/Actions'
import { ExtendedUpdateActionInput } from 'components/Actions/Actions'
import { Card } from 'components/Card'
import { PageHeader } from 'components/PageHeader'
import {
  AccountEventType,
  CreateActionInput,
  Maybe,
  Message,
  SortDirection,
  TriggerQuery,
  UpdateTriggerInput,
  useTriggerQuery,
  useUpdateTriggerMutation
} from 'generated'
import { useDeleteModal } from 'hooks/useDeleteModal'
import { EmailTemplatesCollection, useEmailTemplates } from 'hooks/useEmailTemplates'
import { usePaginatedQuery } from 'hooks/usePaginatedQuery'
import MessagesTable, { MessagesResult } from 'pages/Message/MessagesTable'
import { useEffect, useMemo, useRef } from 'react'
import { Controller, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import DeleteTriggerModal from './DeleteTriggerModal'

const limit = 5

const buildFilter = (filter: any) => {
  const f: any = {}
  if (filter.search) {
    f.or = [{ name: { iLike: `%${filter.search}%` } }, { title: { iLike: `%${filter.search}%` } }]
  }
  if ('enabled' in filter) {
    f.enabled = { is: filter.enabled }
  }
  return f
}

type FormData = UpdateTriggerInput
interface EditTriggerProps extends RouteComponentProps {
  id: string
}

function getFormValues(data: TriggerQuery['trigger']): FormData {
  return {
    name: data?.name,
    enabled: data?.enabled,
    criteria: data?.criteria,
    accountGroupIds: data?.accountGroups?.map(({ id }) => id),
    actions: data?.actions.map((action) => ({
      ...action,
      ...(action.message && { messageId: action.message.id, messageName: action.message.name })
    }))
  }
}

export default function EditTrigger(props: EditTriggerProps): JSX.Element {
  const actionsRef = useRef<ExtendedUpdateActionInput[] | null>(null)
  const location = useLocation() as Location & { state: { comingFromView?: boolean } }

  const navigate = useNavigate()
  const deleteModalProps = useDeleteModal()
  const { data, loading } = useTriggerQuery({ variables: { id: props.id }, fetchPolicy: 'network-only' })
  const [updateTrigger] = useUpdateTriggerMutation({
    onCompleted: async () => {
      toast.success('Successfully updated trigger.')
      if (location.state.comingFromView) {
        navigate(-1)
      }
    },
    onError: (error) => {
      console.log({ error })
      toast.error('Error. could not update trigger.')
    }
  })

  const paginatedMessages = usePaginatedQuery<MessagesResult, Partial<Message>>({
    query: MessagesTable.query,
    accessor: 'messages',
    options: {},
    persist: false,
    limit,
    buildFilter,
    defaultSort: { sortField: 'createdAt', direction: SortDirection.Desc }
  })
  const { data: emailTemplatesData, loading: loadingEmailTemplates } = useEmailTemplates()

  const reloadMessages = () => paginatedMessages.query.refetch()

  const defaultValues = useMemo(() => getFormValues(data?.trigger), [data?.trigger])

  const defaultActions = useMemo(() => {
    if (defaultValues?.actions) {
      return (defaultValues.actions as any[]).map(
        ({
          id,
          messageId,
          voucherValue,
          voucherMessage,
          name,
          type,
          enabled,
          channels,
          delay,
          expiryDate,
          message
        }) => {
          return {
            id,
            name,
            messageId,
            messageName: message?.name ?? '',
            voucherValue,
            voucherMessage,
            type,
            enabled,
            channels,
            delay,
            expiryDate
          }
        }
      )
    } else {
      return []
    }
  }, [defaultValues?.actions])

  const { register, reset, handleSubmit, errors, control, setValue } = useForm<FormData>({
    defaultValues
  })

  useEffect(() => {
    if (data) {
      reset(getFormValues(data.trigger))
    }
  }, [data])

  const onSubmit = (input: FormData) => {
    delete input.actions
    const newActions = JSON.parse(JSON.stringify([...(actionsRef.current ?? [])])) as ExtendedUpdateActionInput[]
    const newCriteria = JSON.parse(JSON.stringify({ ...input.criteria }))
    if (Object.is(newCriteria.maxNumberOfRuns, NaN) || typeof newCriteria.maxNumberOfRuns !== 'number') {
      delete newCriteria.maxNumberOfRuns
    }

    if (newActions && newActions.length > 0) {
      newActions.forEach((action) => {
        delete action.messageName
        if (!action.delay || !action.delay.value || Object.is(action.delay.value, NaN) || action.delay.value <= 0) {
          action.delay = null
        } else {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { __typename, ...rest } = action.delay as any
          action.delay = { ...rest }
        }
        if (
          !action.expiryDate ||
          !action.expiryDate.value ||
          Object.is(action.expiryDate.value, NaN) ||
          action.expiryDate.value <= 0
        ) {
          action.expiryDate = null
        } else {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { __typename, ...rest } = action.expiryDate as any
          action.expiryDate = { ...rest }
        }
      })
    }
    Object.assign(input, { actions: newActions as Maybe<CreateActionInput[]>, criteria: newCriteria })

    updateTrigger({
      variables: { id: props.id, input }
    })
  }
  const openDelete = () => deleteModalProps.openModal(props.id, data?.trigger?.name)

  useEffect(() => {
    if (data?.trigger && !actionsRef.current) {
      actionsRef.current = data?.trigger.actions.map(
        ({ id, messageId, voucherValue, voucherMessage, name, type, enabled, channels, delay, expiryDate }) => ({
          id,
          name,
          messageId,
          voucherValue,
          voucherMessage,
          type,
          enabled,
          channels,
          delay,
          expiryDate
        })
      )
    }
  }, [data?.trigger])
  return (
    <div>
      <DeleteTriggerModal
        {...deleteModalProps}
        onDelete={() => {
          props.navigate?.(`/triggers`)
        }}
      />
      <PageHeader>
        <h1 className="text-lg">Edit Trigger</h1>
        <Button variant="primary" colorScheme="red" onClick={openDelete}>
          Delete
        </Button>
      </PageHeader>
      <div className="p-5">
        <Card>
          {loading || loadingEmailTemplates ? (
            <Loader isActive />
          ) : (
            <>
              <Card.Content>
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <div className="mb-4 items-start">
                    <div className="mb-4">
                      <FormLabel>
                        <strong>Name*</strong>
                        <Input
                          width="full"
                          variant="standard"
                          name="name"
                          ref={register({ required: 'Name is required' })}
                        />
                        {errors.name && <FormError>{errors.name.message}</FormError>}
                      </FormLabel>
                    </div>
                    <div className="mb-4">
                      <FormLabel>
                        <strong>Account Event Type</strong>
                        <p className="text-lg mt-2 text-bold">{data?.trigger?.eventType}</p>
                      </FormLabel>
                    </div>
                    <div className="mb-4">
                      <FormLabel className="col-start-1">
                        <strong className="mr-2">Enabled</strong>
                        <Controller
                          control={control}
                          name="enabled"
                          render={({ onChange, value }) => (
                            <Checkbox
                              id="enabled"
                              className="block"
                              checked={value}
                              onChange={() => onChange(!value)}
                            />
                          )}
                        />
                      </FormLabel>
                    </div>
                    <div className="mb-4">
                      <FormLabel>
                        <strong>Audience Segments</strong>
                        <Controller
                          control={control}
                          name="accountGroupIds"
                          render={({ onChange: onChangeAccounts, value: accountGroupIds }) => (
                            <AccountGroupComboBox
                              accountGroupIds={accountGroupIds}
                              onChangeAccountGroups={onChangeAccounts}
                              hasOperatorSelector={false}
                            />
                          )}
                        />
                      </FormLabel>
                    </div>
                    <div className="col-start-1 col-span-2">
                      <strong>Criteria</strong>
                      <FormLabel className={styles.criteriaItem}>
                        <p>Runs once per account</p>
                        <Controller
                          control={control}
                          name="criteria.oncePerAccount"
                          render={({ onChange, value }) => (
                            <Checkbox id="criteria.oncePerAccount" checked={value} onChange={() => onChange(!value)} />
                          )}
                        />
                      </FormLabel>
                      <FormLabel className={styles.criteriaItem}>
                        <p className="self-start">Max number of runs</p>
                        <div className="flex flex-col items-end">
                          <Input
                            id="criteria.maxNumberOfRuns"
                            name="criteria.maxNumberOfRuns"
                            type="number"
                            ref={register({ valueAsNumber: true, min: 0 })}
                            variant="standard"
                            min={0}
                          />
                          <div
                            className="underline cursor-pointer"
                            onClick={() => setValue('criteria.maxNumberOfRuns', null)}
                          >
                            Unset
                          </div>
                        </div>
                      </FormLabel>
                      {[AccountEventType.OrderConfirmation, AccountEventType.CreateCart].includes(
                        data?.trigger?.eventType as AccountEventType
                      ) ? (
                        <>
                          <FormLabel className={styles.criteriaItem}>
                            <div className="pr-8">
                              <p className="self-start">Order Total</p>
                              <p className="text-xs text-gray-700">
                                The order value for which this trigger should pass and run. Setting this to zero would
                                mean any successful order will pass validation. Setting this to 1500, for example, would
                                mean that only a successful order with a total value of R1500 will have the actions run.
                              </p>
                            </div>
                            <div className="flex flex-col items-end">
                              <Input
                                id="criteria.orderTotal"
                                name="criteria.orderTotal"
                                ref={register({ valueAsNumber: true, min: 0 })}
                                variant="standard"
                                min={0}
                              />
                              <div
                                className="underline cursor-pointer"
                                onClick={() => setValue('criteria.orderTotal', null)}
                              >
                                Unset
                              </div>
                            </div>
                          </FormLabel>
                          <FormLabel className={styles.criteriaItem}>
                            <div className="pr-8">
                              <p className="self-start">Number of Orders</p>
                              <p className="text-xs text-gray-700">
                                Limit this order confirmation trigger to users who have had X many previous orders.
                                Setting this to zero would mean any successful order will pass validation.
                              </p>
                            </div>
                            <div className="flex flex-col items-end">
                              <Input
                                id="criteria.numberOfOrders"
                                name="criteria.numberOfOrders"
                                ref={register({ valueAsNumber: true, min: 0 })}
                                variant="standard"
                                min={0}
                              />
                              <div
                                className="underline cursor-pointer"
                                onClick={() => setValue('criteria.numberOfOrders', null)}
                              >
                                Unset
                              </div>
                            </div>
                          </FormLabel>
                        </>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-span-2">
                    <strong>Actions</strong>
                    <Actions
                      actionsRef={actionsRef}
                      initialActions={defaultActions}
                      {...{
                        paginatedMessages,
                        emailTemplateCollection: emailTemplatesData?.emailTemplatesCollection as EmailTemplatesCollection,
                        reloadMessages
                      }}
                    />
                  </div>
                  <Button colorScheme="green" type="submit" variant="primary">
                    Save
                  </Button>
                </Form>
              </Card.Content>
            </>
          )}
        </Card>
      </div>
    </div>
  )
}

const styles = {
  criteriaItem: 'flex justify-between items-center border-b border-gray-200 py-4'
}

EditTrigger.mutation = gql`
  mutation UpdateTrigger($id: ID!, $input: UpdateTriggerInput!) {
    updateTrigger(id: $id, input: $input) {
      id
    }
  }
`
