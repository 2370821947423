import { OperationVariables, QueryResult } from '@apollo/client'
import { Alert, Loader, Table } from '@plusplusminus/plusplusdash'
import { navigate } from '@reach/router'
import { Badge } from 'components/Badge'
import { Pagination } from 'components/Pagination'
import { RefundsResult } from 'hooks/refunds'
import { TPagination } from 'hooks/usePagination'
import { SortTuple } from 'hooks/useSort'
import React from 'react'
import { formatDate } from 'utils'

const TABLE_SHAPE = [
  { label: 'Order Number', key: 'orderNumber', isSortable: false },
  { label: 'Order Date', key: 'orderDate', isSortable: false },
  { label: 'Customer', key: 'customer', isSortable: false },
  { label: 'Brand', key: 'brandId', isSortable: false },
  { label: 'Subtotal', key: 'subTotal', isSortable: false },
  { label: 'Refund state', key: 'state', isSortable: true },
  { label: 'Note', key: 'note', isSortable: false },
  { label: 'Refund date', key: 'createdAt', isSortable: true },
  { label: 'Updated at', key: 'updatedAt', isSortable: true },
  { label: 'Refund Amount', key: 'basketAmount', isSortable: false },
  { label: 'Payout?', key: 'payoutStatus', isSortable: false }
]

interface RefundsPaginatedProps {
  query: QueryResult<RefundsResult, OperationVariables>
  sort: SortTuple
  pagination: TPagination
}

const RefundsPaginated: React.FC<RefundsPaginatedProps> = ({ query, sort, pagination }) => {
  const { data, previousData, loading, error } = query
  const { page, nextPage, prevPage } = pagination
  const [sortField, direction, sortCallback] = sort
  const tableData = data || previousData

  function onClickRow(refundId: string) {
    return () => {
      navigate(`/refunds/${refundId}`)
    }
  }

  return (
    <div>
      {error ? (
        <Alert type="error">
          <Alert.Heading>There was an error fetching refunds.</Alert.Heading>
        </Alert>
      ) : !previousData && loading ? (
        <Loader isActive />
      ) : tableData ? (
        <>
          <Table
            shape={TABLE_SHAPE}
            sortCallback={sortCallback}
            activeSort={sortField}
            sortDirection={direction?.toLowerCase()}
          >
            {tableData?.refunds.edges.map(({ node: refund }) => {
              const isPayout = !!refund.orderLine?.ledgerItems?.find((ledgerItem) => ledgerItem.code === '9990/000')
              let customerName = ''
              if (refund?.orderLine?.order?.customer?.accountId) {
                customerName = [refund.orderLine.order.customer?.firstName, refund.orderLine.order.customer?.lastName]
                  .filter(Boolean)
                  .join(' ')
              } else {
                customerName = [
                  refund.orderLine?.order.shippingAddress?.firstName,
                  refund.orderLine?.order.shippingAddress?.lastName
                ]
                  .filter(Boolean)
                  .join(' ')
              }

              return (
                <Table.Row
                  key={refund.id}
                  className="cursor-pointer hover:bg-blue-50 brefund-b brefund-gray-100"
                  onClick={onClickRow(refund.id)}
                >
                  <Table.Cell>{refund.orderLine?.order.orderNumber ?? ''}</Table.Cell>
                  <Table.Cell>
                    {refund.orderLine?.order.orderDate ? formatDate(refund.orderLine.order.orderDate) : ''}
                  </Table.Cell>
                  <Table.Cell>{customerName}</Table.Cell>
                  <Table.Cell>
                    <Badge className="mr-1" color="blue">
                      {refund.orderLine?.brand.brandName}
                    </Badge>
                  </Table.Cell>
                  <Table.Cell>R {refund.orderLine?.subTotal}</Table.Cell>
                  <Table.Cell>{refund.state}</Table.Cell>
                  <Table.Cell>{refund.note}</Table.Cell>

                  <Table.Cell>{formatDate(refund.createdAt)}</Table.Cell>
                  <Table.Cell>{formatDate(refund.updatedAt)}</Table.Cell>
                  <Table.Cell>R {refund.basketAmount}</Table.Cell>
                  <Table.Cell>{isPayout ? 'Yes' : 'No'}</Table.Cell>
                </Table.Row>
              )
            })}
          </Table>
          <Pagination
            hasPreviousPage={tableData.refunds.pageInfo.hasPreviousPage}
            hasNextPage={tableData.refunds.pageInfo.hasNextPage}
            onNext={nextPage}
            onPrev={prevPage}
            page={page}
          />
        </>
      ) : null}
    </div>
  )
}

export { RefundsPaginated }
