import { gql, QueryHookOptions } from '@apollo/client'
import { PaginatedEntity } from 'common/types'
import { Order, OrderFilter } from 'generated'
import { PAGING_FIELDS } from '../graphql/fragments'
import { TUsePaginatedQuery, usePaginatedQuery } from './usePaginatedQuery'

/** Types */

type OrdersResult = {
  orders: PaginatedEntity<Order>
}

type FilterFields = {
  account?: string
  brands?: string[]
  startDate?: Date
  endDate?: Date
  orderId: string
  orderNumber: string
  status?: string
  isActive: boolean
}

/** Fragments */

export const ORDER_ADDRESS_FIELDS = gql`
  fragment OrderAddressFields on OrderAddress {
    addressLine1
    addressLine2
    suburb
    city
    province
    postalCode
    country
    phone
    note
  }
`

export const ACCOUNTING_CODE_FIELDS = gql`
  fragment AccountingCodeFields on AccountCode {
    id
    code
    description
  }
`

export const ORDER_LINE_FIELDS = gql`
  fragment OrderLedgerFields on OrderLedger {
    id
    code
    createdAt
    isCredit
    amount
    description
    isSystem
  }
  fragment OrderLineFields on OrderLine {
    id
    subTotal
    shippingHandle
    shippingTotal
    status
    paymentDate
    orderStatus {
      status
      shippingStatus
      confirmed
      orderReference
    }
    issues {
      error
    }
    brand {
      id
      brandName
      notes {
        product
        cart
        alert
        checkout
        shipping
        freeShippingThreshold
      }
    }
    items {
      id
      quantity
      price
      productVariant {
        id
        title
        stockStatus
        product {
          id
          productTitle
          stockStatus
          status
          image {
            ...ImageFields
          }
        }
      }
    }
    ledgerItems {
      ...OrderLedgerFields
    }
    shippingMethods {
      title
      price {
        amount
        currencyCode
      }
      id: handle
    }
    invoiceUrl
    paymentAdviceUrl
  }
`

const BASE_ORDER_FIELDS = gql`
  fragment BaseOrderFields on Order {
    id
    createdAt
    updatedAt
    orderDate
    isActive
    subTotal
    orderNumber
    productCount
    status
    shippingAddress {
      firstName
      lastName
    }
    customer {
      id
      firstName
      lastName
      accountId
      email
      mobile
    }
    metadata {
      checkout {
        platform
      }
    }
  }
`

export const ORDER_FIELDS = gql`
  fragment OrderFields on Order {
    ...BaseOrderFields
    useCredit
    totalPaid
    creditUsed
    shippingAddress {
      ...OrderAddressFields
    }
    billingAddress {
      ...OrderAddressFields
    }
    lines {
      ...OrderLineFields
    }
    payments {
      id
      amount
      createdAt
      method
      state
    }
  }
  ${BASE_ORDER_FIELDS}
  ${ORDER_LINE_FIELDS}
  ${ORDER_ADDRESS_FIELDS}
`

/** Queries */

const ORDERS_QUERY = gql`
  query OrdersQuery($paging: CursorPaging, $filter: OrderFilter, $sorting: [OrderSort!]) {
    orders(paging: $paging, filter: $filter, sorting: $sorting) {
      edges {
        node {
          ...BaseOrderFields
          lines {
            brand {
              brandName
            }
          }
        }
      }
      pageInfo {
        ...PagingFields
      }
    }
  }
  ${PAGING_FIELDS}
  ${BASE_ORDER_FIELDS}
`

/** Mutations */

/** Hooks */

function useOrdersQuery(args: {
  options?: QueryHookOptions
  persist: boolean
  isActive: boolean
  defaultFilter?: OrderFilter
}): TUsePaginatedQuery<OrdersResult> {
  const { options, persist = false, isActive = false, defaultFilter = {} as OrderFilter } = args

  return usePaginatedQuery<OrdersResult, Order>({
    query: ORDERS_QUERY,
    accessor: 'orders',
    options,
    persist,
    buildFilter: buildOrdersFilter(isActive, defaultFilter)
  })
}

/** Utils */

export const buildOrdersFilter = (isActive: boolean, defaultFilter: OrderFilter) => (filter: FilterFields) => {
  const f = defaultFilter

  if (filter.brands && filter.brands.length > 0) {
    f.lines = { or: filter.brands.map((id) => ({ brandId: { eq: id } })) }
  }
  if (filter.account) {
    f.or = [
      { customer: { email: { iLike: `%${filter.account}%` } } },
      { customer: { firstName: { iLike: `%${filter.account}%` } } },
      { customer: { lastName: { iLike: `%${filter.account}%` } } }
    ]
  }
  if (filter.orderNumber) {
    f.orderNumber = { eq: parseInt(filter.orderNumber) }
  }
  if (filter.startDate && filter.endDate) {
    f[isActive ? 'createdAt' : 'orderDate'] = { between: { lower: filter.startDate, upper: filter.endDate } }
  }
  if (filter.status) {
    f.status = { eq: filter.status }
  }
  if ('isActive' in filter) {
    f.isActive = { is: filter.isActive }
  }

  return f
}

export { useOrdersQuery }
export type { FilterFields, OrdersResult }
